const cards = [
   {
      id: "img01",
      target: "",
      alt: "Banqueta Alta Quadrada madeira de demolição",
      title: "Banqueta Alta Quadrada",
      description: "Madeira de demolição",
   },
   {
      id: "img02",
      target: "",
      alt: "Banqueta Ondulada",
      title: "Banqueta Ondulada",
      description: "Medida: 50cm. Madeira de demolição",
   },
   {
      id: "img03",
      target: "",
      alt: "Banqueta Oriental",
      title: "Banqueta Oriental",
      description: "Cor: Branca. Medida: 50cm. Madeira de demolição",
   },
   {
      id: "img04",
      target: "",
      alt: "Banqueta Flórida Corda",
      title: "Banqueta Flórida Corda",
      description: "",
   },
   {
      id: "img05",
      target: "",
      alt: "Banqueta Flórida Longa",
      title: "Banqueta Flórida Longa",
      description: "",
   },
   {
      id: "img06",
      target: "",
      alt: "Banqueta Mississipi",
      title: "Banqueta Mississipi",
      description: "",
   },
   {
      id: "img07",
      target: "",
      alt: "Banqueta Orla",
      title: "Banqueta Orla",
      description: "",
   },
   {
      id: "img08",
      target: "",
      alt: "Banqueta Primavera",
      title: "Banqueta Primavera",
      description: "",
   },
   {
      id: "img09",
      target: "",
      alt: "Banqueta Reta",
      title: "Banqueta Reta",
      description: "",
   },
   {
      id: "img10",
      target: "",
      alt: "Banqueta Flórida Colmeia",
      title: "Banqueta Flórida Colmeia",
      description: "",
   },
]

export { cards }
import React from "react"

import { BannerSection,
         WrapBanner } from "./styles"

const BannerHero = ({ imgFluid }) => {

   return (
      <BannerSection>
         <WrapBanner fluid={imgFluid} />
      </BannerSection>
   )
}

export default BannerHero
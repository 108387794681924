import React from "react"
import styled from "styled-components";
import media from "../styles/breakPoints"

import MainSection from "./MainSection"
import ButtonPlus from "./ButtonPlus"

const WrapTitle = styled(MainSection)`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`

const Title = styled.h1`
   width: 100%;
   height: 50px;
   font-family: ${props => props.theme.font.primary};
   font-weight: 600;
   font-size: ${props => props.theme.size.medium};
   line-height: 50px;
   color: ${props => props.theme.color.primaryLight2};
   background-color: ${props => props.theme.color.fourthLight1};
   text-align: center;
   text-transform: uppercase;
   margin: 0;
`

const Subtitle = styled.h2`
   width: 100%;
   font-family: ${props => props.theme.font.primary};
   font-weight: 600;
   font-size: ${props => props.theme.size.medium};
   color: ${props => props.theme.color.primaryLight2};
   text-align: center;
   margin: 0;
   padding: 10px 20px;

   ${media.greaterThan("lg")`
      padding: 15px;
   `}

`

const Description = styled.p`
   width: 100%;
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.small};
   line-height: 2rem;
   color: ${props => props.theme.color.primaryLight2};
   text-align: center;
   margin: 0;
   padding: 0 10px 10px;

   ${media.greaterThan("sm")`
      width: 576px;
      margin: 0 auto;
      padding: 0 10px 20px;
   `}

`

const Button = styled(ButtonPlus)`
   margin: 10px;
`

const TitleProduct = ({ title, 
                        subtitle, 
                        description, 
                        label, 
                        onClick }) => {

   return (
      <WrapTitle>
         <Title>{title}</Title>
         {subtitle && <Subtitle>{subtitle}</Subtitle>}
         {description && <Description>{description}</Description>}
         {label && <Button onClick={onClick}
                           label={label}
                           className="-inverse" />}
      </WrapTitle>
   )
}

export default TitleProduct
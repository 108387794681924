import styled from "styled-components";
import BackgroundImage from "gatsby-background-image"

import MainSection from "../../objects/MainSection" 

const BannerSection = styled(MainSection)`
   position: relative;
   width: 100vw;
   height: calc(100vw * 0.4877);
   max-height: 515px;
   margin: 0 0 16px;
   box-sizing: border-box;
`

const WrapBanner = styled(BackgroundImage)`
   width: 100%;
   height: 100%;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
`

export {
   BannerSection,
   WrapBanner
}